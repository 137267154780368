<template>
  <section
    class="project-config"
    :class="{ 'scroll-content': !store_setup_import }"
  >
    <h3
      :class="{
        'md-headline': store_setup_import,
        'md-display-1': !store_setup_import
      }"
    >
      {{ $translate("project_configuration.title") }}:
    </h3>

    <section_description_preview>
      <div class="md-body-1">
        {{ $translate("project_configuration.message") }}
      </div>
    </section_description_preview>

    <div
      class="project-config__config md-layout md-gutter md-alignment-top"
    >
      <div
        v-for="(config_part, index) in config"
        :key="index"
        class="md-layout-item md-size-33 md-small-size-100"
      >
        <div class="project-config__config-item md-elevation-3">
          <div
            v-for="(config_child, index) in config_part"
            :key="`project-config-${index}`"
            class="project-config__config-key md-layout md-gutter md-alignment-top"
          >
            <div
              v-for="[key, val] in config_child"
              :key="`project-config-${key}`"
              class="md-layout-item md-size-100"
            >
              <section_description_preview
                v-if="key !== 'project_name'"
                :bottom_margin="false"
              >
                <template v-slot:title>
                  <div class="md-body-2">
                    {{ $translate(`project_configuration.values.${key}.title`) }}:
                  </div>
                </template>
                <div class="md-body-1">
                  {{ $translate(`project_configuration.values.${key}.message`) }}
                </div>
              </section_description_preview>

              <p
                v-if="key === 'email_service' && show_mail_service_message"
                class="project-config__message"
                v-html="$translate(`project_configuration.messages.${email_service}`)"
              />
              <p
                v-if="key === 'order_email_password' && email_service === 'Gmail'"
                class="project-config__message"
                v-html="$translate(`project_configuration.messages.Gmail_password`)"
              />
              <md-field
                :class="{
                  'project-config__error':
                    key === 'domain' && show_domain_error
                }"
              >
                <label>{{
                  $translate(`project_configuration.values.${key}.title`)
                }}</label>
                <md-select
                  :disabled="!can_user_manage_configuration"
                  v-if="key === 'email_service'"
                  v-model="email_service"
                  name="email-service"
                  id="email-service"
                >
                  <md-option
                    v-for="provider in email_service_providers"
                    :key="`email-provider-${provider}`"
                    :value="provider"
                    >{{ provider }}</md-option
                  >
                </md-select>
                <md-input
                  v-else
                  :disabled="!can_user_manage_configuration"
                  :value="val"
                  :type="key.includes('password') ? 'password' : 'text'"
                  @change="event => update_config(key, event.target.value)"
                />
                <span
                  v-if="key === 'domain' && show_domain_error"
                  class="md-helper-text"
                >
                  {{ $translate(`project_configuration.domain_error`) }}
                </span>
              </md-field>
              <md-button
                class="md-primary md-raised"
                :class="{ 'project-config__email-button-disabled': !enable_test_email_button }"
                v-if="key === 'order_email_password'"
                @click="enable_test_email_button ? run_email_connection_test() : null"
              >
                <md-tooltip md-direction="top">{{
                  enable_test_email_button ?
                    $translate("project_configuration.test_connection_tooltip", { email: get_correct_config.order_email }) :
                    $translate("project_configuration.test_connection_tooltip_disabled")
                }}</md-tooltip>
                {{ $translate("project_configuration.test_connection") }}
              </md-button>
              <transition name="project-config-email-success">
                <p v-if="email_test_send && key === 'order_email_password'" class="project-config__test-message">
                  {{ $translate("project_configuration.test_connection_success", { email: get_correct_config.order_email }) }}
                </p>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import {
  ADMIN,
  CONFIGURATION_STORE,
  USER_STORE
} from "../../../constants/others_constants"
import { CONFIGURATION_DATA_UPDATED, UPDATE_LOADER } from "../../../stores/Admin/constants"
import {
  UPDATE_PROJECT_CONFIG,
  UPDATE_PROJECT_NAME
} from "../../../stores/Admin/configuration/constants"
import empty_project_config from "../../../constants/empty_objects/empty_project_config"
import email_service_providers from "../../../constants/email_service_providers"
import domain_normalizer from "../../../methods/domain_normalizer"
import domain_verification from "../../../methods/domain_verification"
import { test_email_connection } from "../../../constants/endpoints/other"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    section_description_preview
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    },
    store_setup_import: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      show_mail_service_message: false,
      email_test_send: false,
      show_domain_error: false,
      mail_services_with_message: ["Seznam", "Gmail"],
      email_service_providers
    };
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["project_config", "project_name", "error_list"]),
    ...mapState(USER_STORE, ["user_info"]),
    ...mapState(ADMIN, ["user_edited_website_configuration"]),
    get_correct_config() {
      return {
        ...empty_project_config,
        ...this.project_config,
        project_name: this.project_name
      };
    },
    config() {
      return [
        [
          Object.entries({ domain: this.get_correct_config.domain }),
          Object.entries({ project_name: this.get_correct_config.project_name })
        ],
        [
          Object.entries({
            email_service: this.get_correct_config.email_service,
            info_email: this.get_correct_config.info_email
          })
        ],
        [
          Object.entries({
            order_email: this.get_correct_config.order_email,
            order_email_password: this.get_correct_config.order_email_password
          })
        ]
      ];
    },
    email_service: {
      get() {
        return this.get_correct_config.email_service;
      },
      set(val) {
        this.update_config("email_service", val);
        this.show_mail_service_message = this.mail_services_with_message.includes(
          this.email_service
        );
        this.configuration_data_updated({ types_updated: ["project_config", "project_name"] });
      }
    },
    enable_test_email_button(){
      return this.get_correct_config.order_email &&
        this.get_correct_config.order_email_password &&
        !Object.values(this.user_edited_website_configuration).length &&
        Object.values(this.user_edited_website_configuration).every(val => val) &&
        !this.error_list.length
    }
  },
  watch: {
    get_correct_config() {
      this.evaluate_values();
    }
  },
  mounted() {
    this.show_mail_service_message = this.mail_services_with_message.includes(
      this.email_service
    );
    this.evaluate_values();
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_project_config: UPDATE_PROJECT_CONFIG,
      update_project_name: UPDATE_PROJECT_NAME
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED,
      update_loader: UPDATE_LOADER,
    }),
    test_email_connection,
    async run_email_connection_test() {
      this.update_loader()
      const { data } = await test_email_connection({
        email: this.get_correct_config.order_email,
        project_id: this.get_correct_config.project_id,
        language: this.user_info.language_code
      })
      this.update_loader(false)

      if (data === true) {
        this.email_test_send = true

        setTimeout(() => this.email_test_send = false, 5000);
      }
    },
    update_config(key, value) {
      this.show_domain_error = false;

      if (key === "domain") {
        if (!domain_verification(value)) return (this.show_domain_error = true);

        value = domain_normalizer(value);
        this.update_project_config(["domain_updated", true]);
        this.update_project_config([key, ""]);

        this.$nextTick(() => this.update_project_config([key, value]));
      } else if (key === "project_name")
        value && this.update_project_name(value);
      else this.update_project_config([key, value]);

      this.configuration_data_updated({ types_updated: ["project_config", "project_name"] });
    },
    evaluate_values() {
      const all_necessary_filled_out = Object.values({
        domain: this.get_correct_config.domain,
        project_name: this.get_correct_config.project_name,
        email_service: this.get_correct_config.email_service,
        info_email: this.get_correct_config.info_email,
        order_email: this.get_correct_config.order_email,
        order_email_password: this.get_correct_config.order_email_password
      }).every(val => val);

      this.$emit("is_filled_out", all_necessary_filled_out);
    }
  }
};
</script>

<style lang="scss">
@use "../../../../../styles/_admin-constants" as *;
@use "../../../../../styles/_global-constants" as *;

.project-config {
  .md-field {
    width: auto !important;
    max-width: 500px !important;
  }

  &__error {
    &:after {
      background-color: $material-red !important;
    }
    .md-helper-text {
      color: $material-red !important;
    }
  }

  &__email-button-disabled {
    cursor: auto !important;
    color: var(--md-theme-default-disabled, rgba(0,0,0,0.26)) !important;
    background-color: var(--md-theme-default-disabled-background-on-background, rgba(0,0,0,0.12)) !important;
    box-shadow: none !important;
  }

  &__config {
    padding: 0 5px;
    margin: 0 !important;
    align-items: normal;
    align-content: flex-start;

    &-key {
      align-items: flex-start;
      align-content: flex-start;
    }

    &-item {
      padding: $half-default-size $default-size;
      margin-bottom: $default-size;
    }
  }

  &__message {
    display: inline-block;
    padding: $half-default-size;
    background-color: $material-orange;
    border-radius: 2px;
    color: $pure-black;
    margin: 0 0 $default-size;
    word-break: break-word;

    a {
      color: #182fab !important;
    }
  }

  &__test-message {
    background: $green;
    color: $pure-white;
    padding: $half-default-size $default-size;
    border-radius: $border-radius;
  }

  .project-config-email-success-enter-active {
    transition: .2s;
  }
  .project-config-email-success-leave-active {
    opacity: 0.5;
  }
}
</style>
