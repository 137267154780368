export default url => {
  let domain

  if (url.indexOf("//") > -1) domain = url.split("/")[2]
  else domain = url.split("/")[0]

  return domain
    .toLowerCase()
    .split(":")[0]
    .split("?")[0]
    .replace("www.", "")
    .replace(/ /g, "")
}
