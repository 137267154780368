var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"project-config",class:{ 'scroll-content': !_vm.store_setup_import }},[_c('h3',{class:{
      'md-headline': _vm.store_setup_import,
      'md-display-1': !_vm.store_setup_import
    }},[_vm._v(" "+_vm._s(_vm.$translate("project_configuration.title"))+": ")]),_c('section_description_preview',[_c('div',{staticClass:"md-body-1"},[_vm._v(" "+_vm._s(_vm.$translate("project_configuration.message"))+" ")])]),_c('div',{staticClass:"project-config__config md-layout md-gutter md-alignment-top"},_vm._l((_vm.config),function(config_part,index){return _c('div',{key:index,staticClass:"md-layout-item md-size-33 md-small-size-100"},[_c('div',{staticClass:"project-config__config-item md-elevation-3"},_vm._l((config_part),function(config_child,index){return _c('div',{key:`project-config-${index}`,staticClass:"project-config__config-key md-layout md-gutter md-alignment-top"},_vm._l((config_child),function([key, val]){return _c('div',{key:`project-config-${key}`,staticClass:"md-layout-item md-size-100"},[(key !== 'project_name')?_c('section_description_preview',{attrs:{"bottom_margin":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"md-body-2"},[_vm._v(" "+_vm._s(_vm.$translate(`project_configuration.values.${key}.title`))+": ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"md-body-1"},[_vm._v(" "+_vm._s(_vm.$translate(`project_configuration.values.${key}.message`))+" ")])]):_vm._e(),(key === 'email_service' && _vm.show_mail_service_message)?_c('p',{staticClass:"project-config__message",domProps:{"innerHTML":_vm._s(_vm.$translate(`project_configuration.messages.${_vm.email_service}`))}}):_vm._e(),(key === 'order_email_password' && _vm.email_service === 'Gmail')?_c('p',{staticClass:"project-config__message",domProps:{"innerHTML":_vm._s(_vm.$translate(`project_configuration.messages.Gmail_password`))}}):_vm._e(),_c('md-field',{class:{
                'project-config__error':
                  key === 'domain' && _vm.show_domain_error
              }},[_c('label',[_vm._v(_vm._s(_vm.$translate(`project_configuration.values.${key}.title`)))]),(key === 'email_service')?_c('md-select',{attrs:{"disabled":!_vm.can_user_manage_configuration,"name":"email-service","id":"email-service"},model:{value:(_vm.email_service),callback:function ($$v) {_vm.email_service=$$v},expression:"email_service"}},_vm._l((_vm.email_service_providers),function(provider){return _c('md-option',{key:`email-provider-${provider}`,attrs:{"value":provider}},[_vm._v(_vm._s(provider))])}),1):_c('md-input',{attrs:{"disabled":!_vm.can_user_manage_configuration,"value":val,"type":key.includes('password') ? 'password' : 'text'},on:{"change":event => _vm.update_config(key, event.target.value)}}),(key === 'domain' && _vm.show_domain_error)?_c('span',{staticClass:"md-helper-text"},[_vm._v(" "+_vm._s(_vm.$translate(`project_configuration.domain_error`))+" ")]):_vm._e()],1),(key === 'order_email_password')?_c('md-button',{staticClass:"md-primary md-raised",class:{ 'project-config__email-button-disabled': !_vm.enable_test_email_button },on:{"click":function($event){_vm.enable_test_email_button ? _vm.run_email_connection_test() : null}}},[_c('md-tooltip',{attrs:{"md-direction":"top"}},[_vm._v(_vm._s(_vm.enable_test_email_button ? _vm.$translate("project_configuration.test_connection_tooltip", { email: _vm.get_correct_config.order_email }) : _vm.$translate("project_configuration.test_connection_tooltip_disabled")))]),_vm._v(" "+_vm._s(_vm.$translate("project_configuration.test_connection"))+" ")],1):_vm._e(),_c('transition',{attrs:{"name":"project-config-email-success"}},[(_vm.email_test_send && key === 'order_email_password')?_c('p',{staticClass:"project-config__test-message"},[_vm._v(" "+_vm._s(_vm.$translate("project_configuration.test_connection_success", { email: _vm.get_correct_config.order_email }))+" ")]):_vm._e()])],1)}),0)}),0)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }